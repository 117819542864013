window.addEventListener('load', function () {
    var shown = false;
    var yTrigger = 0;
    var timeLaps = 500;
    var running = false;

    $('footer').click(function (e) {
        var $this = $(this);
        if (!shown && !running) {
            var delta = $this.innerHeight();
            $this.toggleClass("open");
            myTop = $this.css('margin-top');

            yTrigger = $(window).height() - delta;
            running = true;
            $this.velocity({ 'margin-top': '-' + delta + 'px' }, { duration: timeLaps, complete: function () { running = false; } });
            shown = true;
        }
        else {
            if ((yTrigger < e.screenY) && !running) {
                $this.toggleClass("open");
                running = true;
                $this.velocity({ 'margin-top': '0px' }, { duration: timeLaps, complete: function () { running = false; } });
                shown = false;
            }
        }
    });
}, false);